/*Circle of 5ths*/
:root {
    --deg30: 30deg;
    --transp: rgba(0, 0, 0, 0.0);
    --transp50: rgba(255, 255, 255, 0.5);
    --white1: rgba(255, 255, 255, 1.0);
    --yellow: rgb(255, 216, 0, .5);
    --black2: rgba(0, 0, 0, 0.95);
}

.keyCircles:hover {
    border: 1px solid red;
}

.keyMenu {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--black2);
    width: 0vw;
    height: 0vw;
    z-index: 0;
}

/* keyMenu & Circles*/
.keyMenu.active {
    width: 100vw;
    height: 100vh;
    z-index: 3;

}

.keyMenu  li  {
    list-style: none;

}
.keyMenu li div {
    position: absolute;
    padding: 1em;
    left: 0;
    visibility: hidden;
    transform-origin: 50vw;
    transition: 0.2s;
    transition-delay: calc(0.01s * var(--i));
}

.keyMenu.active li div {
    transform: rotate(calc(360deg / 12 * var(--i)));
    visibility: visible;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 25vw;
    height: 25vw;
}

.keyMenu.active li div img {
    transform: rotate(calc(360deg / -12 * var(--i)));
    width: 100%;
}


/*Major Minor buttons*/

.mj_mode {
    font-size: 10em;
}



.mn_mode {
}
/*.menu.active .mode {
 color: var(--transp50);
}*/






