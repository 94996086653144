.settMenu {
    position: fixed;
    display: block;
    justify-content: center;
    align-items: center;
    background-color: var(--black2);
    width: 100vw;
    height: 0vw;
    z-index: 4;
    overflow: hidden;
}

.settMenu.active {
    width: 100vw;
    height: 100vh;
}


.settItem {
    padding: 1em;
    background-color: rgba(20, 20, 20, .85);
    width: 100%;
   
}

    .settItem > p {
        color: darkgoldenrod;
        text-decoration: none;
        font-size: 1.5em;
    }

.accPanel {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 0;
    opacity: -100;
    overflow-y: hidden;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;
    transition: height 1s ease;
    transition: opacity 1s ease;
}

.accPanel.active{
    height: auto;
    opacity: 100;
    transition: height 1s ease;
    transition: opacity 1s ease; 
}

accPanel.active > p {
    display:block;
}

.text{
    padding: 2em;
}

.instOpt {
    min-width: 99vw;
    /* outline: 2px solid red; */
    scroll-snap-align: start;
    scroll-snap-stop: always;
    scroll-snap-type: mandatory;
}

    .instOpt > img {
        max-height: 100vw;
        /* outline: 4px solid blue; */
    }
    
    .instOpt > p {
        margin: 0;
        padding: .5em;
        font-weight: bold;
        text-align: left;
        color:rgba(195, 195, 195, 0.85);
        /* outline: 2px solid yellow; */
    }

    .instOpt > h3 {
        margin: 0;
        padding: 1em;
        font-weight: bold;
        color:rgb(155, 155, 155);
    }

    .instOpt > h1 {
        margin: 0;
        padding-top: 1em;
        padding-bottom: .5em;
        font-weight: bold;
        font-size: 1.25em;
        color:rgb(155, 155, 155);
    }

.next {
    position: absolute;
    top: 66%;
    width:7vw;
    height: auto;
    opacity: 30%;
    /* outline: solid green 2px ; */
}

.left {
    left: 5vw;
    rotate: 180deg;
}

.right {
    right: 5vw;
}

.chordMenu {
    position: fixed;
    display: block;
    justify-content: center;
    /* align-items: center; */
    background-color: var(--black2);
    padding: 0;
    width: 100vw;
    height: 0vw;
    z-index: 2;
    overflow: hidden;
}

.chordMenu.active {
    width: 100vw;
    height: 100vh;
    padding-top: 15vh;
}