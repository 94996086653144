/*VARIABLES*/
:root {
    --transp: rgba(0,0,0,0);
    --grad_k: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,1), rgba(255,0,0,0));
    --white1: #ffffff;
    --black1: #000000;
    --gray1: #555;
    --fretTrans: rgba(255,255,255,.05);
    --fretTrans2: rgba(255,255,255,.1);
    --fretTrans3: rgba(255,255,255,.15);
    --fretTrans4: rgba(255,255,255,.2);
    --fretTrans5: rgba(155,155,120,.3);
    --fadeTime: .7s;
}


/*CHARTS*/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0px solid black;
    color: var(--white1);
    font-family: sans-serif;
    font-size: 1em;
    text-align: center;
    display:
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: var(--black1) !important;
    height: 100vh;
    overflow: scroll;

}

/*Nav Bar*/
.titlenav {
    position: fixed;
    top: 0;
    width: 100%;
    background-image: var(--grad_k);
    padding: .2em;
    padding-bottom: 4vh;
    z-index: 4;
}

/*text Titles*/
#chartTitle1 {
    /* position: relative; */
    font-variant-caps: small-caps;
    font-size: 2rem;
    line-height: 2.25rem;
}

#chartTitle2 {
    /* position: relative; */
    font-variant-caps: small-caps;
    font-size: 2rem;
    line-height: 2.25rem;

}

.keyName1 {
    color: var(--white1);
}

.keyName2 {
    color: var(--gray1);
}

#titlePipe {
    position: relative;
    color: var(--white1);
    font-variant-caps: small-caps;
    font-size: 2rem;
}

#chartDesc {
    position:relative;
    color: var(--white1);
    background-color: var(--black1);
    font-size: 1rem;
    top: 1em;
}

#chartDesc.active {
    top: 0em;
}

.icon-tut {
    max-width: 7vw;
    display: inline;
}

.icon-tut2 {
    max-width: 30vw;
    display: inline;

}

/*Buttons*/

#sett-btn {
    position: fixed;
    right: 3vw;
    top: 5.5vh;
    width: 15vw;
    z-index: 5;
    transition: top .2s ease;
}

#sett-btn.active {
    top: .125vh;
    transition: top .2s ease;
}

#xmenu1-btn {
    position: fixed;
    left: 3vw;
    top: 3.5vh;
    width: 15vw;
    opacity: 0;
    z-index: 3;

}

#xmenu1-btn.active {
    opacity: 100;
    top: 5.5vh;
    transition: all .5s ease;
    z-index: 4;

}

#is-sharp-btn {
    position: fixed;
    left: 3vw;
    top: 5.5vh;
    width: 15vw;
    z-index: 4;
}

#is-sharp-btn.active {
    left: 3vw;
    top: 33vh;
    transition: top .75s ease;
}

#chord-btn {
    position: fixed;
    left: 3vw;
    top: 23vh;
    width: 15vw;
    z-index: 3;
    transition: all .5s ease;

}

#chord-btn.active {
    left: 24vw;
    top: 10vh;
    transition: all .5s ease;
}

#sound-btn {
    position: fixed;
    right: 3vw;
    top: 13vh;
    width: 15vw;
    z-index: 3;
    transition: all .5s ease;

}

#sound-btn.active {
    right: 24vw;    
    top: 10vh;
    transition: all .5s ease;

}


.chord-row {
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.chord-item {
    width: 25vw;
    /* height: 50vh; */
    /* outline: solid blue 3px; */
    margin: 1em;
    border: #555 2px solid;
    border-radius: 3em;
    padding: 1rem 0;
}


.chord-item > p {
    margin: 0;
    padding: .65rem 0rem;
    
}

#i-chord, #iii-chord, #vi-chord {
    position: relative;
    top: -1.5rem;
}

.chord-name {
    font-size: 2em;
    background-color: #555;
}

.chord-number {
    font-size: 2em;
    line-height: 2.25rem;
    font-family: 'Times New Roman', Times, serif;
}

.chord-triad {
    padding: 1.5em  0 1.5em 0; 
    background-color: #333;

}



#inst-btn {
    position: fixed;
    left: 3vw;
    top: 35vh;
    width: 15vw;
    z-index: 1;

}

#fret-btn {
    position: fixed;
    left: 3vw;
    top: 13vh;
    width: 15vw;
    z-index: 2;
}

/* fingerboard */
.wrapper {
    position: absolute;
    top: 0;
    width: 100vw;
    /* overflow-y: scroll; */
    height: 105%;
    transition: all .5s;
}

/*FingerBoard Chart*/
#fngrbrd_container {
    position:absolute;
    color: var(--white1);
    aspect-ratio: 9 / 48;
    width: 100%;
    top: 3vh;
    background-position: bottom;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-clip: border-box;
    outline: 1px solid green;
    display: grid;
    grid-template-rows: 4% 2.3% 5.1% 5.1% 5.0% 4.7% 4.7% 4.5% 4.3% 4.2% 4.1% 3.9% 3.8% 3.8% 3.4% 3.2% 3.0% 2.6% 3.0% 25%;
    transition: all .5s;
    z-index: 0;
    
    /*transform: scale(.8);*/
}



.v4_bg{
    background-image: url("../public/images/v4_bg.png");
}
.v5_bg{
    background-image: url("../public/images/v5_bg.png");
}
.va_bg{
    background-image: url("../public/images/va_bg.png");
}
.vc_bg{
    background-image: url("../public/images/vc_bg.png");
}

.chart-frets {
    position: relative;
    padding-left: 15.5%;
    padding-right: 15.5%;
    border: 1px solid var(--fretTrans3);
    background-color: var(--transp);
    display: grid;
    border-radius: 10% / 40%;
    transition: all .5s;
    z-index: 0;
    
}

.fret-highlight {
    border: 1px solid var(--white1);
    background-color: var(--fretTrans3);
    transition: all .5s;

}

.fret-lowlight {
    border: 1px solid var(--fretTrans4);
    background-color: var(--fretTrans2);
    transition: all .5s;

}

/* Strang Quantity per row */
.strangs4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.strangs4 > div {
    background-size: 9vw;
}

.strangs5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.strangs5 > div{
    background-size: 7.5vw;

}

.fretLabel {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: .25em;
    font-variant-caps: small-caps;
    font-weight: bolder;
    color: var(--fretTrans4);
    transition: color .5s;
}
/* The marker place */
.mrkPlace {
    background-image: url('');
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity .4s;
    /* outline: solid red 2px; */
}

/* Unique padding for each fret row*/
#_op {
    padding-left: 30% !important;
    padding-right: 30% !important;
    border: 0px solid black;
    background-color: var(--transp);
    position: -webkit-sticky;
    position: sticky;
    top: 22vw;
    z-index: 1;
}

#_1L {
    padding-left: 29.5% !important;
    padding-right: 29.5% !important;
}

#_1H {
    padding-left: 28.5% !important;
    padding-right: 28.5% !important;
}

#_2L {
    padding-left: 27.5% !important;
    padding-right: 27.5% !important;
}

#_2H {
    padding-left: 26.8% !important;
    padding-right: 26.8% !important;
}

#_3L {
    padding-left: 25.5% !important;
    padding-right: 25.5% !important;
}

#_3H {
    padding-left: 24.5% !important;
    padding-right: 24.5% !important;
}

#_4 {
    padding-left: 23.5% !important;
    padding-right: 23.5% !important;
}

#_5L {
    padding-left: 22.5% !important;
    padding-right: 22.5% !important;
}

#_5H {
    padding-left: 22% !important;
    padding-right: 22% !important;
}

#_6L {
    padding-left: 20.5% !important;
    padding-right: 20.4% !important;
}

#_6H {
    padding-left: 19.5% !important;
    padding-right: 18.6% !important;
}

#_7L {
    padding-left: 17.8% !important;
    padding-right: 17.8% !important;
}

#_7H {
    padding-left: 16% !important;
    padding-right: 16% !important;
}

#_8L {
    padding-left: 15.5% !important;
    padding-right: 15.5% !important;
}

#_8h {
    padding-left: 14.5% !important;
    padding-right: 14.5% !important;
}

#_9L {
    padding-left: 13.5% !important;
    padding-right: 13.5% !important;
}

#_9H {
    padding-left: 12.5% !important;
    padding-right: 12.5% !important;
}
